import React from "react"

import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import ChildPage from "../components/layout/page"
import Section from "../components/layout/section"

import { FixedContainer } from "../components/layout/container"
import PublicationsList from "../components/publications/PublicationsList"

// { pageContext, children, data, path }
const ResearchTopic = ({ data }) => {
  const { mdx } = data
  const { frontmatter, fields } = mdx
  const { slug } = fields

  const { body } = mdx
  
  const { publications } = frontmatter
  const { publicURL } = frontmatter.bannerImage
  const parallax = {
    image: publicURL
  }

  const breadcrumbs = [
    { "key": "home", "title": "Home", "path": "/" },
    { "key": "research", "title": "Research", "path": "/research" },
    { "key": "topic", "title": frontmatter.smallTitle, "path": slug }
  ]

  return (
    <ChildPage 
      title="Research" 
      subtitle={frontmatter.title}
      breadcrumbs={breadcrumbs}
      altTitle={frontmatter.title}
      parallax={parallax}
    >
      <Section>
        <FixedContainer>
          <article>
            
            <MDXRenderer>{body}</MDXRenderer>
            {publications && (
              <footer className="article-footer">
                <div className="footer-header">
                  <h5>Related Publications</h5>
                </div>
                <div className="footer-body">
                  <PublicationsList publications={publications} includeMeta={false} />
                </div>
              </footer>
            )}
          </article>
        </FixedContainer>
      </Section>
    </ChildPage>
  )
}

export default ResearchTopic

export const pageQuery = graphql`query ResearchTopicQuery($id: String) {
  mdx(id: {eq: $id}) {
    id
    body
    fields {
      slug
    }
    frontmatter {
      title
      smallTitle
      bannerImage {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      publications {
        ...BibTexInformation
      }
    }
  }
}
`